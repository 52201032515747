import { css } from '@emotion/react';
import React, { ReactElement } from 'react';
import styled, { CSSProp } from 'styled-components';
import tw from 'twin.macro';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement;
  hasDeem?: boolean;
  contentPositionStyle?: CSSProp;
};

const Modal = (props: ModalProps): ReactElement | null => {
  if (!props.isOpen) {
    return null;
  }
  return props.hasDeem ? (
    <DIV_ModalOverlay>
      <ModalContainer {...props} />
    </DIV_ModalOverlay>
  ) : (
    <ModalContainer {...props} />
  );
};

const ModalContainer = (props: ModalProps): ReactElement => {
  return (
    <div
      css={[
        props.contentPositionStyle ||
          css`
            top: 110px;
            left: 30px;
          `,
        ModalContainerStyle.default,
        props.hasDeem ? ModalContainerStyle.deem : ModalContainerStyle.noDeem,
      ]}
    >
      <button
        type="button"
        css={tw`absolute top[20px] right[20px] cursor-pointer hover:(opacity-70)`}
        onClick={props.onClose}
      >
        <img
          width={25}
          height={25}
          alt="닫기 아이콘"
          src={`${process.env.NEXT_PUBLIC_CLOUD_FRONT}/dalock3.0/icons/close.svg`}
        />
      </button>
      {props.children}
    </div>
  );
};
const DIV_ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;

const ModalContainerStyle = {
  default: css`
    width: max-content;
    height: max-content;
    background-color: white;
    @media (max-width: 767px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  deem: css`
    position: absolute;
  `,
  noDeem: css`
    position: fixed;
    z-index: 1000;
  `,
};

export default Modal;
