import { ReactElement } from 'react';

type DisableScrollProps = {
  enable: boolean;
};
const DisableScroll = ({ enable }: DisableScrollProps): ReactElement | null => {
  if (!enable) {
    return null;
  }

  return (
    <style global jsx>{`
      html {
        overflow: hidden;
      }
    `}</style>
  );
};

export default DisableScroll;
