import DisableScroll from 'common/components/DisableScroll';
import Modal from 'common/components/modal/Modal';
import useWindowSize from 'common/hooks/useWindowResize';
import type { GetStaticProps } from 'next';
import { NextPage } from 'next';
import { SSRConfig, useTranslation } from 'next-i18next';
import i18nextConfig from 'next-i18next.config';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { css } from 'styled-components';
import tw from 'twin.macro';

const Home: NextPage = () => {
  const bgImageURL = `${process.env.NEXT_PUBLIC_CLOUD_FRONT}/partners/ssak/main/main_bg.jpg`;
  const { t } = useTranslation('home');
  const { isMobile } = useWindowSize();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    setIsOpen(true);
  }, []);

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <section
      style={{ backgroundImage: `url(${bgImageURL})` }}
      css={[
        tw`relative`,
        tw`h-[calc(100vh - 80px)]`,
        tw`min-h-[500px] box-border`,
        tw`bg-center bg-no-repeat bg-cover`,
      ]}
    >
      <article
        css={[
          tw`absolute top-0 left-0`,
          tw`w-[100%] h-[100%]`,
          tw`pt-[20vh]`,
          tw`px-80 x_1024:(px-40)`,
          css`
            background: rgba(0, 0, 0, 0.2);
          `,
        ]}
      >
        <span
          css={[
            tw`box-content`,
            tw`inline-block mb-20`,
            tw`font-bold font-NotoSansCJKkr text-[#fff] text-shadow[0px 4px 12px rgba(0, 0, 0, 0.25)]`,
            tw`text-56 leading-112`,
            tw`x_1440:(text-48 leading-96)`,
            tw`x_1024:(text-38 leading-76)`,
            tw`x_430:(text-28 leading-56)`,
          ]}
        >
          {t('title')}
        </span>

        <header
          css={[
            tw`mb-50 max-w-1280`,
            tw`font-bold font-NanumSquare text-[#fff] text-shadow[0px 4px 12px rgba(0, 0, 0, 0.25)]`,
            tw`whitespace-pre-line text-32 leading-57`,
            tw`x_1440:(text-26 leading-46)`,
            tw`x_1024:(text-22 leading-39)`,
            tw`x_430:(text-16 leading-28)`,
          ]}
        >
          {t('header.h1')}
          {t('header.h2')}
        </header>

        <Link
          href={{
            pathname: '/association',
          }}
          target={'_blank'}
          rel="noreferrer"
          passHref
        >
          <a
            css={[
              tw`inline-block py-10 px-30`,
              tw`bg-[#45525C] border[1px solid #fff] box-shadow[ 0px 4px 12px rgba(0, 0, 0, 0.15)] rounded-full`,
              tw`font-bold font-NanumSquare text-[#fff]`,
              tw`text-20 leading-30`,
              tw`x_430:(text-16 leading-24)`,
              tw`hover:opacity-80`,
            ]}
          >
            {t('intro')}
          </a>
        </Link>
      </article>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={handleClose} hasDeem={isMobile}>
          <>
            <DisableScroll enable={isMobile} />
            <img
              css={tw`w-[450px] h-auto x_768:(w-[100vw] max-w-[450px])`}
              alt="협회 공지문"
              src={`${process.env.NEXT_PUBLIC_CLOUD_FRONT}/ssak/0125_popup.svg`}
            />
          </>
        </Modal>
      )}
    </section>
  );
};

export const getStaticProps: GetStaticProps<SSRConfig> = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(
        locale as string,
        ['home'],
        i18nextConfig,
      )),
    },
  };
};

export default Home;
